import React from "react";

export default function AboutMe({ isMobile }) {
  return (
    <>
      {isMobile && <p style={{ fontWeight: "500", fontSize: "26px" }}>About me:</p>}
      <p>
        Since a young age, I have been deeply intrigued and inspired by exceptional product design, particularly in the
        realm of software.
      </p>
      <p>
        With years of programming experience and a Master's degree from Technical University Berlin, I now excel at
        bridging the gap between the clients' ideas and the developers' implementation, ensuring seamless product
        development.
      </p>
      <p style={{ fontWeight: "500", fontSize: "26px", marginBottom: "10px" }}>Projects I am proud of:</p>
    </>
  );
}
