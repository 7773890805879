import React from "react";
import "./App.css";
import { useState, useRef, useEffect } from "react";

import ProfileImage from "./components/ProfileImage";
import Contact from "./components/Contact";
import AboutMe from "./components/AboutMe";
import Projects from "./components/Projects";
import Imprint from "./components/Imprint";

// underline the project title
// do not center everything, left align
// remove margins/gaps on mobile

function FelixPaulus() {
  const [showImprint, setShowImprint] = useState(false);
  const rightColumnRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add scroll handler to container
  const handleWheel = (e) => {
    if (isMobile) return; // Don't handle wheel events on mobile
    if (rightColumnRef.current) {
      e.preventDefault();
      rightColumnRef.current.scrollTop += e.deltaY;
    }
  };

  if (isMobile) {
    return (
      <div className="App">
        <Imprint showImprint={showImprint} setShowImprint={setShowImprint} />
        <div
          className="container mobile-container"
          onClick={() => {
            setShowImprint(false);
          }}
        >
          <ProfileImage />
          <h1 className="h1-custom">
            Hey! <br></br>I'm Felix Paulus,<br></br> a product manager.
          </h1>
          <Contact />
          <AboutMe isMobile={true} />
          <Projects />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <Imprint showImprint={showImprint} setShowImprint={setShowImprint} />
      <div
        className="container"
        onClick={() => {
          setShowImprint(false);
        }}
        onWheel={handleWheel}
      >
        <div className="left-column">
          <div className="left-column-spacer-1" />
          <ProfileImage />
          <Contact />
        </div>
        <div className="right-column" ref={rightColumnRef}>
          <div className="right-column-spacer-1" />
          <h1 className="h1-custom">
            Hey! <br></br>I'm Felix Paulus,<br></br> a product manager.
          </h1>
          <AboutMe />
          <Projects />
          <div className="right-column-spacer-2" />
        </div>
      </div>
    </div>
  );
}

export default FelixPaulus;
