import React from "react";
import bloomImage from "../assets/images/BloomBetterBullets_Logo_256.png";
import sight2sightImage from "../assets/images/s2s_square.001.png";
import firaImage from "../assets/images/logo_fira.png";

const PROJECTS = [
  {
    title: "Bloom Shopify App",
    link: "https://apps.shopify.com/bloom-product-feature-bullets",
    image: bloomImage,
    description1:
      "A Shopify app designed to help merchants boost conversions by enhancing storefronts with dynamic product features, delivery dates, and countdown timers.",
    snacks: [
      "Scaled to 10M+ monthly requests across thousands of global merchants, with a 4.8/5 star rating",
      "Implemented major updates without service interruptions, improving user satisfaction and retention."
    ]
  },
  {
    title: "SIGHT2SIGHT.com",
    link: "https://sight2sight.com/",
    image: sight2sightImage,
    description1:
      "A travel platform that combines thousands of activities from different tourism providers into a single booking experience.",
    snacks: [
      "Developed an editing pipeline that reduces time-to-market for new listings from 2 days to 3 minutes.",
      "Designed and implemented a complex data pipeline integrating multiple major tourism APIs, translating content and leveraging AI to refine it."
    ]
  },
  {
    title: "Fira Shopify App",
    link: "https://apps.shopify.com/fira-ai-video-to-3d-model",
    image: firaImage,
    description1:
      "A shopify app that creates photorealistic 3D models from short smartphone videos, leveraging the latest developments in Gaussian Splatting algorithms.",
    snacks: [
      "Achieved a 90% reduction in 3D model creation costs compared to traditional photogrammetry methods.",
      "Designed an intuitive user workflow that achieved a high completion rate for first-time users, despite the technical complexity."
    ]
  }
];

export default function Projects() {
  return (
    <div className="project-list">
      {PROJECTS.map((project, index) => (
        <a key={index} href={project.link} target="_blank" rel="noreferrer" className="project-link">
          <div className="project-container">
            <img
              src={project.image}
              alt={project.title}
              className={`project-image ${index === 1 ? "project-image-border" : ""}`}
            />
            <div className="project-description-1">
              <div className="project-title">{project.title}</div>
              <div className="project-description">{project.description1}</div>
              <ul className="project-snacks">
                {project.snacks.map((snack, i) => (
                  <li key={i}>{snack}</li>
                ))}
              </ul>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}
