import React from "react";
import sunIcon from "../assets/images/sun_icon.png";

export default function Imprint({ showImprint, setShowImprint }) {
  if (!showImprint) return null;

  return (
    <div className="imprint-modal">
      <span
        className="imprint-close-icon fa-regular fa-close fa-custom"
        onClick={() => {
          setShowImprint(false);
        }}
      ></span>
      <div className="imprint-content">
        <img className="imprint-sun-icon" src={sunIcon} alt="" />
        <br />
        <strong>Imprint</strong>
        <br />
        <br />
        Felix Paulus <br />
        Leipziger Str. 46 <br />
        10117 Berlin
      </div>
    </div>
  );
}
