import React from "react";
import leinwandzauberLogo from "../assets/images/leinwandzauber_logo.png";
import deutscheMetallbauerLogo from "../assets/images/deutsche_metallbauer_logo.ico";
import betterbulletsLogo from "../assets/images/BloomBetterBullets_Logo_256.png";
import rapidLogo from "../assets/images/Rapid_Logo_256.png";
import bambooLogo from "../assets/images/Bamboo_Logo_256.png";
import hoshoLogo from "../assets/images/hosho_logo.ico";
import CV from "../assets/files/CV.pdf";

export const CONTACT = [
  {
    prefix: <span className="fa-regular fa-envelope fa-custom"></span>,
    text: "hello@felixpaulus.com",
    link: "mailto:hello@felixpaulus.com"
  },
  {
    prefix: <span className="fa-brands fa-github fa-custom"></span>,
    text: "github.com/felixmpaulus",
    link: "https://github.com/felixmpaulus"
  },
  {
    prefix: <span className="fa-brands fa-linkedin fa-custom"></span>,
    text: "linkedin.com/in/felix-paulus",
    link: "https://linkedin.com/in/felix-paulus-720b58142/"
  },
  {
    prefix: <span className="fa-regular fa-mobile fa-custom"></span>,
    text: "+49 176 5764 9202",
    link: "tel:004917657649202"
  },
  // {
  //   prefix: <span className="fa-regular fa-location-dot fa-custom"></span>,
  //   text: "Berlin, Germany",
  // },
  {
    prefix: <span className="fa-regular fa-file-user fa-custom"></span>,
    text: "CV",
    link: CV
  }
];

export const INFORMATION = [
  {
    title: "Portfolio",
    icon: <img className="icon-black-to-gray" alt="" />,
    values: [
      {
        prefix: <img className="content-prefix-image" src={bambooLogo} alt="" />,
        text: "Bamboo: Easy Announcement Bar",
        link: "https://apps.shopify.com/bamboo-easy-announcement-bar",
        furtherInformation: [
          "Shopify App zur Erstellung von Announcement Bars",
          "Ziel: Erfolg von Bloom: Better Bullets fortführen",
          "Livegang im Januar 2023"
        ]
      },
      {
        prefix: <img className="content-prefix-image" src={rapidLogo} alt="" />,
        text: "Rapid: Easy Editor",
        link: "https://apps.shopify.com/rapid-easy-editor",
        furtherInformation: [
          "Shopify App zum Bulk Editing von sehr vielfältigen Produktpaletten",
          "Ziel: Kategorisieren von tausenden Leinwandbildern beschleunigen",
          "Livegang im Juli 2022"
        ]
      },
      {
        prefix: <img className="content-prefix-image" src={leinwandzauberLogo} alt="" />,
        text: "Leinwandzauber.com",
        link: "https://www.leinwandzauber.com/",
        furtherInformation: [
          "Shopify Dropshipping Store für die Nische von mehrteiligen Leinwänden",
          "Verantwortlich für funktionelle und visuelle Anpassungen der Shopify-Stores sowie für die Automatsierung der Produktverwaltung",
          "Livegang im Oktober 2021"
        ]
      },
      {
        prefix: <img className="content-prefix-image" src={betterbulletsLogo} alt="" />,
        text: "Bloom: Better Bullets",
        link: "https://apps.shopify.com/bloom-better-bullets",
        furtherInformation: [
          "Shopify App zur Erweiterung von Produktseiten",
          "Ziel: Shopify-Ecosystem besser kennen lernen",
          "Livegang im Mai 2022"
        ]
      },
      {
        prefix: <img className="icon-black-to-gray content-prefix-image" src={deutscheMetallbauerLogo} alt="" />,
        text: "Deutsche-Metallbauer.de",
        link: "https://deutsche-metallbauer.de/",
        furtherInformation: [
          "Lead-Generierung für Metallbaubetriebe",
          "Verantwortlich für gesamte Entwicklung via Next.js",
          "Livegang im Mai 2021"
        ]
      },
      {
        prefix: <img className="icon-black-to-gray content-prefix-image" src={hoshoLogo} alt="" />,
        text: "HOSHO.de",
        link: "https://hosho.de/",
        furtherInformation: [
          "B2B-Möbelvermietung für Mitarbeiter im Home-Office",
          "Verantwortlich für gesamte Entwicklung",
          "Livegang Juli 2020"
        ]
      }
    ]
  }
];
