import React from "react";
import { CONTACT } from "../constants/constants";

export default function Contact() {
  return (
    <div className="contact-information">
      {CONTACT.map(({ prefix, text, link }, i) => {
        return (
          <div key={i} className="content-list-item-header">
            {prefix}
            <h3 className="h3-custom">
              {link ? (
                <a
                  className="content-list-item-text"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  download={text === "My CV" ? "CV_Felix_Paulus.pdf" : undefined}
                >
                  {text}
                </a>
              ) : (
                <div className="content-list-item-text">{text}</div>
              )}
            </h3>
          </div>
        );
      })}
    </div>
  );
}
