import React from "react";
import mainImage from "../assets/images/Foto_Felix_Paulus_Quadrat_1.jpg";

export default function ProfileImage() {
  return (
    <div className="main-image-container">
      <img className="main-image" src={mainImage} alt="Foto von Felix Paulus" />
    </div>
  );
}
